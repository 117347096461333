import axios from 'axios';
import { domain } from './domain';

export const login = (data) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        data,
    };
    return axios(domain + '/login', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};

export const updateUser = (token, data) => {
    const requestOptions = {
        method: 'PUT',
        headers: { 
            'Content-type': 'application/json',
            "Authorization" : "Bearer " +token 
        },
        data,
    };
    return axios(domain + '/user', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};

export const auth = (token) => {
    const requestOptions = {
        method: 'GET',
        headers: { Authorization: 'Bearer ' + token },
    };
    return axios(domain + '/token', requestOptions)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};